import {VERSION} from "./version";

export const environment = {
  MacroHelixSupportUrl: 'https://help.mhiapps.com/',
  production: true,
  appVersion: "-"+VERSION.hash+"-qa",
  apiBaseUrl: 'https://qaapi.mhiapps.com/',
  baseUrl: 'https://qaapp.mhiapps.com',
  loginUrl: 'https://qa.mhiapps.com/',
  ssoLoginUrl: 'https://fedsvc.mckesson.com/adfs/ls/',
  subcriptionKey: 'fdfdd55e4d5d45b0b7b446a43068f5bb',
  UAMUserApiEndpoint: "user/",
  appInsights: {
    instrumentationKey: '65900d66-3bf3-4d44-b86e-55e4c7bedce9',
    applicationName: 'MHIApps'
  },
  b2c: {
    TenantName: "mhiappsb2cqa",
    SignInUrl: "https://mhiappsb2cqa.b2clogin.com/mhiappsb2cqa.onmicrosoft.com/b2c_1a_mhiappsqa_emailonlysignin/oauth2/v2.0/authorize",
    ClientId: "483797d7-1e58-48ad-af10-a03334a68a97",
    RedirectUrl: "https://ngqa.mhiapps.com/",
    LogoutUrl: "https://qa.mhiapps.com/",
    SignInPolicy: "B2C_1A_mhiappsqa_EmailOnlySignIn",
    OldSignInPolicy: "B2C_1A_mhiappsqa_JITMigrationSignIn",
    ResetPasswordPolicy: "B2C_1A_mhiappsqa_PasswordReset",
    LoggedInPasswordResetPolicyId: "B2C_1A_mhiappsqa_PasswordChange",
    UserChangeMFAPolicyId: "B2C_1A_mhiappsqa_MFAChange",
    UserApiEndpoint: "user/v1/login",
    SignUpPolicy: "B2C_1A_mhiappsqa_OnboardingSignUp",
    ConnectSignInPolicy: "B2C_1A_mhiappsdev_ConnectSignIn"
  },
  analyticsReportNames: '340BI Reporting,340BI Optimization,340BI Account Table',
  embeddingPageUrl: 'https://qaapp.mhiapps.com/EmbeddingPage.aspx'
};
