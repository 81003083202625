import { UAMActionTypes } from './uam.actions';
import { EntityGroupRequest, EntityGroupResponse, EntityGroupsDTO } from 'src/app/models/User-Access-Management/entity-groups';
import { UserGroupRequest, UserGroupsDTO, UserDetails } from 'src/app/models/User-Access-Management/user-groups';
import { Role, UpdateDeleteResponse } from 'src/app/models/User-Access-Management/roles';
import { FileUploadResponse, User } from 'src/app/models/User-Access-Management/user';
import { PermissionResponse, ProductFeaturePermission } from 'src/app/models/User-Access-Management/permissions';
import { ClientResponse } from 'src/app/models/User-Access-Management/client';
import { EntityGroupsHospitalDTO } from 'src/app/models/User-Access-Management/entity-group-hospital';
import { EntityGroupsPharmacyDTO } from 'src/app/models/User-Access-Management/entity-group-pharmacy';
import { UserEntityRoleItemResponse } from 'src/app/models/User-Access-Management/user-entity-role';
import { CorporateParentUAM, CorporateParentUAMDTO } from 'src/app/models/User-Access-Management/corporate-parent';
import { ChainGroupUAMDTO } from 'src/app/models/User-Access-Management/chain-group';
import { UserClientAccess, UserClientAccessRequest } from 'src/app/models/User-Access-Management/user-client-access';
import { ClientProductFeatureRequest, ProductClientFeature, ProductClientFeatureModel } from 'src/app/models/User-Access-Management/product-client-features';
import { BulkUserErrorDetailResponse, BulkUserUploadListItem, BulkUserUploadListResponse } from '../../models/User-Access-Management/bullk-user-upload';
import { TempAccess } from 'src/app/models/temp-access';
import { UserGroupHistoryMember } from 'src/app/models/User-Access-Management/user-group-history-response';
import { EntityGroupHistory } from 'src/app/models/User-Access-Management/entity-group-history';
import { UserHistory } from 'src/app/models/User-Access-Management/user-history-response';

export interface UamState {
  roles: Role[],
  users: User[],
  entityGroups: EntityGroupsDTO,
  nonparentalEntityGroups: EntityGroupsDTO,
  permissionResponse: PermissionResponse,
  updateRoleresponse: number,
  deleteRoleresponse: number,
  addUserResponse,
  initialClientList: ClientResponse,
  searchClientList: ClientResponse,
  entityGroupsHospitalList: EntityGroupsHospitalDTO,
  selectedEntityGroupHspList: EntityGroupsHospitalDTO,
  entityGroupRequest: EntityGroupRequest,
  errorMessage: string,
  entityGroupPharmacyList: EntityGroupsPharmacyDTO,
  userGroupsList: UserGroupsDTO,
  entityName: string,
  deleteEntityGroupsresponse: number,
  deleteUserGroupresponse: number,
  deleteBulkUserGroupresponse: number[],
  entityNameValidation: any,
  entityGroupResponse: EntityGroupResponse[],
  userGroupsResponse: UserGroupRequest,
  roleName: string,
  userDetails: UserDetails,
  userGroupName: string,
  userEntityRoles: UserEntityRoleItemResponse,
  deleteUserGroupMemberResponse: UpdateDeleteResponse,
  userEntityRoleResponse: number,
  deleteuserEntityRoleResponse: UpdateDeleteResponse,
  corporateParentList: CorporateParentUAMDTO,
  chainGroupList: ChainGroupUAMDTO,
  userClientAccessResponse: UserClientAccess,
  userClientAccessRequest: UserClientAccessRequest[],
  validateUserDetailsResponse: any,
  productClientFeatureResp: ProductClientFeature[],
  resendUserInviteResponse: string,
  userEntityRoleValidation: number,
  productFeaturesResp: ClientProductFeatureRequest[],
  bulkUserUploadResponse: FileUploadResponse,
  bulkUserUploadList: BulkUserUploadListResponse,
  bulkUserErrorDetails: BulkUserErrorDetailResponse,
  deleteBulkEntityGroupResponse: number[],
  userGroupsByCorpIdList: UserGroupsDTO,
  tempAccessData: TempAccess[],
  tempAccessResponse: string,
  deleteBulkRolesResponse: UpdateDeleteResponse,
  deleteUserResponse: UpdateDeleteResponse,
  userGroupHistoryResponse: UserGroupHistoryMember[],
  viewHistoryresponse: EntityGroupHistory[],
  userHistoryResponse: UserHistory[],
}

export const initialState: UamState = {
  roles: null,
  users: null,
  entityGroups: null,
  permissionResponse: null,
  updateRoleresponse: null,
  deleteRoleresponse: null,
  addUserResponse: null,
  initialClientList: null,
  searchClientList: null,
  entityGroupsHospitalList: null,
  selectedEntityGroupHspList: null,
  entityGroupRequest: null,
  errorMessage: null,
  entityGroupPharmacyList: null,
  userGroupsList: null,
  entityName: null,
  deleteEntityGroupsresponse: null,
  deleteUserGroupresponse: null,
  entityNameValidation: null,
  entityGroupResponse: null,
  userGroupsResponse: null,
  roleName: null,
  userDetails: null,
  userGroupName: null,
  userEntityRoles: null,
  deleteUserGroupMemberResponse: null,
  userEntityRoleResponse: null,
  deleteuserEntityRoleResponse: null,
  corporateParentList: null,
  chainGroupList: null,
  userClientAccessResponse: null,
  userClientAccessRequest: null,
  validateUserDetailsResponse: null,
  productClientFeatureResp: null,
  nonparentalEntityGroups: null,
  resendUserInviteResponse: null,
  userEntityRoleValidation: null,
  productFeaturesResp: null,
  bulkUserUploadResponse: null,
  bulkUserUploadList: null,
  bulkUserErrorDetails: null,
  deleteBulkUserGroupresponse: null,
  deleteBulkEntityGroupResponse: null,
  userGroupsByCorpIdList: null,
  tempAccessData: null,
  tempAccessResponse: null,
  deleteBulkRolesResponse: null,
  deleteUserResponse: null,
  userGroupHistoryResponse: null,
  viewHistoryresponse: null,
  userHistoryResponse: null
};

export function uamStateReducer(state = initialState, action: any): UamState {
  switch (action.type) {
    case UAMActionTypes.ROLE_GET_RES: {
      return { ...state, roles: action.roles };
    }
    case UAMActionTypes.PERMISSIONS_GET_RES: {
      return { ...state, permissionResponse: action.response };
    }
    case UAMActionTypes.ROLE_PERMISSIONS_GET_RES: {
      return { ...state, permissionResponse: action.response };
    }
    case UAMActionTypes.ROLE_UPDATE: {
      return { ...state, updateRoleresponse: action.data };
    }
    case UAMActionTypes.ENTITY_GROUPS_GET_RES: {
      return { ...state, entityGroups: action.entityGroups };
    }
    case UAMActionTypes.NONPARENTAL_ENTITY_GROUPS_GET_RES: {
      return { ...state, nonparentalEntityGroups: action.entityGroups };
    }
    case UAMActionTypes.USER_GET_RES: {
      return { ...state, users: action.data };
    }
    case UAMActionTypes.ENTITY_GROUPS_HOSPITAL_GET_RES: {
      return { ...state, entityGroupsHospitalList: action.entityGroupsHospitalList };
    }
    case UAMActionTypes.ADD_ENTITY_GROUP_MEMBER_RES: {
      return { ...state, entityGroupRequest: action.result };
    }
    case UAMActionTypes.ROLE_DELETE: {
      return { ...state, deleteRoleresponse: action.data }
    }
    case UAMActionTypes.USER_ADDUPDATE_RES: {
      return { ...state, addUserResponse: action.newUser }
    }
    case UAMActionTypes.CLIENT_GET_RES: {
      return { ...state, initialClientList: action.response }
    }
    case UAMActionTypes.USER_ADDUPDATE_ERR: {
      return { ...state, errorMessage: action.errorMessage }
    }
    case UAMActionTypes.ENTITY_GROUPS_PHARMACY_GET_RES: {
      return { ...state, entityGroupPharmacyList: action.entityGroupPharmacyList }
    }
    case UAMActionTypes.USER_GROUPS_RES: {
      return { ...state, userGroupsList: action.userGroupsList }
    }
    case UAMActionTypes.ENTITY_NAME_VALIDATION_RES: {
      return { ...state, entityName: action.entityName }
    }
    case UAMActionTypes.Search_Corps_Chain_Groups_GET_RES: {
      return { ...state, searchClientList: action.response }
    }
    case UAMActionTypes.ENTITY_GROUPS_DELETE: {
      return { ...state, deleteEntityGroupsresponse: action.data }
    }
    case UAMActionTypes.USER_GROUPS_DELETE: {
      return { ...state, deleteUserGroupresponse: action.data }
    }
    case UAMActionTypes.GET_ENTITY_GROUP_BY_ID_RES: {
      return { ...state, entityGroupResponse: action.data }
    }
    case UAMActionTypes.GET_USER_GROUP_BY_ID_RES: {
      return { ...state, userGroupsResponse: action.userGroupsResponse }
    }
    case UAMActionTypes.ROLE_NAME_VALIDATION_RES: {
      return { ...state, roleName: action.roleName }
    }
    case UAMActionTypes.GET_USER_DETAILS_RES: {
      return { ...state, userDetails: action.userDetails }
    }
    case UAMActionTypes.USER_GROUP_NAME_VALIDATION_RES: {
      return { ...state, userGroupName: action.userGroupName }
    }
    case UAMActionTypes.GET_USER_ENTITY_ROLES_RES: {
      return { ...state, userEntityRoles: action.response }
    }
    case UAMActionTypes.USER_GROUP_MEMBER_DELETE_RES: {
      return { ...state, deleteUserGroupMemberResponse: action.response }
    }
    case UAMActionTypes.ADD_USER_ENTITY_ROLE_RES: {
      return { ...state, userEntityRoleResponse: action.response };
    }
    case UAMActionTypes.DELETE_USER_ENTITY_ROLE_RES: {
      return { ...state, deleteuserEntityRoleResponse: action.response }
    }
    case UAMActionTypes.GET_CORPORATE_PARENT_RES: {
      return { ...state, corporateParentList: action.corporateParentList };
    }
    case UAMActionTypes.GET_CHAIN_GROUP_RES: {
      return { ...state, chainGroupList: action.chainGroupList };
    }
    case UAMActionTypes.GET_USER_CLIENT_ACCESS_RES: {
      return { ...state, userClientAccessResponse: action.userClientAccessResponse };
    }
    case UAMActionTypes.ADD_USER_CLIENT_ACCESS_RES: {
      return { ...state, userClientAccessRequest: action.userClientAccessRequest };
    }
    case UAMActionTypes.VALIDATE_USER_DETAILS_RES: {
      return { ...state, validateUserDetailsResponse: action.response }
    }
    case UAMActionTypes.GET_PRODUCT_CLIENT_FEATURES_RES: {
      return { ...state, productClientFeatureResp: action.productClientFeatureResp };
    }
    case UAMActionTypes.RESEND_USER_INVITE_RES: {
      return { ...state, resendUserInviteResponse: action.response };
    }
    case UAMActionTypes.USER_ENTITY_ROLE_VALIDATION_RES: {
      return { ...state, userEntityRoleValidation: action.data }
    }
    case UAMActionTypes.ADD_DELETE_PRODUCT_FEATURES_RES: {
      return { ...state, productFeaturesResp: action.productFeatureResp };
    }
    case UAMActionTypes.ALL_USER_GET_RES: {
      return { ...state, users: action.data };
    }
    case UAMActionTypes.BULK_USER_UPLOAD_FILE_RES: {
      return { ...state, bulkUserUploadResponse: action.data };
    }
    case UAMActionTypes.CLEAR_USER_ACCESS_SEARCH: {
      return { ...state, users: null, userClientAccessResponse: null }
    }
    case UAMActionTypes.BULK_USER_UPLOAD_LIST_RES: {
      return { ...state, bulkUserUploadList: action.data }
    }
    case UAMActionTypes.BULK_USER_ERROR_DETAIL_RES: {
      return { ...state, bulkUserErrorDetails: action.data }
    }
    case UAMActionTypes.BULK_USER_GROUPS_DELETE: {
      return { ...state, deleteBulkUserGroupresponse: action.data }
    }
    case UAMActionTypes.BULK_ENTITY_GROUPS_DELETE: {
      return { ...state, deleteBulkEntityGroupResponse: action.data }
    }
    case UAMActionTypes.USER_GROUPS_BY_CORP_ID_RES: {
      return { ...state, userGroupsByCorpIdList: action.userGroupsList }
    }
    case UAMActionTypes.GET_TEMP_ACCESS_DATA_RES: {
      return { ...state, tempAccessData: action.tempAccessData }
    }
    case UAMActionTypes.REQUEST_TEMP_ACCESS_RES: {
      return { ...state, tempAccessResponse: action.tempAccessResponse }
    }
    case UAMActionTypes.BULK_ROLES_DELETE_RES: {
      return { ...state, deleteBulkRolesResponse: action.data }
    }
    case UAMActionTypes.USER_DELETE: {
      return { ...state, deleteUserResponse: action.data }
    }
    case UAMActionTypes.GET_USER_GROUP_HISTORY_RES: {
      return { ...state, userGroupHistoryResponse: action.response }
    }
    case UAMActionTypes.VIEW_ENTITY_GROUP_HIS_RES: {
      return { ...state, viewHistoryresponse: action.viewHistoryresponse }
    }
    case UAMActionTypes.GET_USER_HISTORY_RES: {
      return { ...state, userHistoryResponse: action.response }
    }
    default: {
      return state;
    }
  }
}
